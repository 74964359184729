import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import {
  DeviceItemInterface,
  OpenBarrierData,
  OptionItemInterface,
  SalesListItemInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
  Way,
} from '../../../interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { useInput } from '../../../hooks/useInput'
import { useValidation } from '../../../hooks/useValidation'
import { openBarrierDataValidations } from '../../../../constants'
import { useLogout } from '../../../hooks/useLogout'
import { useSelector } from 'react-redux'
import { selectDeviceStateList } from '../../../../store/slices/deviceStateListSlice'

const initialValuePattern: OpenBarrierData = {
  deviceId: '',
  carNumber: '',
}

const validations: ValidationRulesInterface[] = openBarrierDataValidations

export const useActivateBarrier = (
  way: Way,
  setModalActive: React.Dispatch<SetStateAction<boolean>>,
  deviceId?: string,
  parkingId?: string,
  current?: SalesListItemInterface,
  getList?: (reUsed?: boolean) => void,
) => {
  const [stage, setStage] = useState<'start' | 'inProgress' | 'done' | 'error'>(
    'start',
  )
  const [devicesList, setDevicesList] = useState<DeviceItemInterface[]>([])
  const [api] = useState(() => getApi())
  const [data, setData] = useState<OpenBarrierData>(initialValuePattern)
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(false)
  const deviceStateList = useSelector(selectDeviceStateList)

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData, checkAndAddValidation)
  const { logOut } = useLogout()

  const getDevicesList = useCallback(async () => {
    try {
      const result = await api.getDeviceList({
        _start: '0',
        _end: '100',
        _sort: '_id',
        _order: 'desc',
        isActive: '',
        parkingId: current?.parkingId,
      })

      setDevicesList(result.data.filter((el) => !el.entry))
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, current?.parkingId, logOut])

  const checkDeviceActive = (id: string) => {
    const device = deviceStateList.find((el) => el.deviceId === id)
    if (device) return true
    return false
  }

  const getDeviceList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of devicesList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.deviceId,
      }
      checkDeviceActive(item.deviceId) && arr.push(pattern)
    }
    return arr
  }

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  const openBarrier = useCallback(async () => {
    try {
      setStage('inProgress')
      if (current && current.id && getList) {
        await api.openBarrier({
          saleId: current.id,
          carNumber: data.carNumber,
          deviceId: data.deviceId,
          parkingId: parkingId,
          way: way,
        })
        await getList(true)
      } else {
        await api.openBarrier({
          deviceId: data.deviceId,
          carNumber: data.carNumber,
          parkingId: parkingId,
          way: way,
        })
      }
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 2500))
      setStage('done')
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      setModalActive(false)
    } catch (error: any) {
      setStage('error')
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      setModalActive(false)
    }
  }, [api, current, data.deviceId, getList, setModalActive, data.carNumber, way])

  useEffect(() => {
    deviceId && setData((prev) => ({ ...prev, deviceId: deviceId }))
  }, [deviceId])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    getDevicesList()
  }, [api])

  useEffect(() => {
    getDeviceList()
  }, [devicesList])

  useEffect(() => {
    if (devicesList.length === 1) {
      setData((prevState) => ({
        ...prevState,
        deviceId: devicesList[0].deviceId ? devicesList[0].deviceId : '',
      }))
    }
  }, [devicesList])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  return {
    stage,
    setStage,
    openBarrier,
    data,
    setDirty,
    getDeviceList,
    inputHandler,
    getValidationResult,
    disabled,
  }
}
