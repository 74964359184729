import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ClientDisconnectedEventData,
  DeviceState,
} from '../interfaces/interfaces'
import { selectAuthToken } from '../../store/slices/authSlice'
import { io, Socket } from 'socket.io-client'
import {
  addDevice,
  removeDevice,
  selectDeviceStateList,
  setDeviceStateList,
} from '../../store/slices/deviceStateListSlice'

export const useSocket = () => {
  // Socket ...
  const socket = useRef<Socket | null>(null)
  const [isConnected, setIsConnected] = useState(false)
  const token = useSelector(selectAuthToken)
  const dispatch = useDispatch()
  const deviceStateList = useSelector(selectDeviceStateList)

  // from store
  const setActiveDeviceListByDeviceId = (payload: DeviceState) => {
    const index = deviceStateList.findIndex(
      (item) => item.deviceId === payload.deviceId,
    )
    if (index !== -1) {
      dispatch(removeDevice(payload.clientId))
      console.log('remove double device')
      return
    }
    dispatch(addDevice(payload))
    console.log('add device')
    return
  }

  // from store
  const removeDeviceState = (clientId: string) => {
    dispatch(removeDevice(clientId))
    console.log('remove device')
  }

  // from store
  const removeAllDeviceStates = () => {
    dispatch(setDeviceStateList([]))
  }

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(
        window.location.host.split(':')[0] === 'localhost'
          ? 'http://localhost:5009/admin-panel'
          : 'https://as-parking.ru/admin-panel',
      )
    }
    return () => {
      socket.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isConnected && token) {
      socket.current?.emit('auth', token)
      socket.current?.emit('update-device-state', token)
    }
  }, [token, isConnected])

  useEffect(() => {
    if (!socket.current) return
    socket.current.on('connect', () => {
      setIsConnected(true)
    })
    socket.current.on('update-device-state', (state: DeviceState) => {
      setActiveDeviceListByDeviceId(state)
    })

    socket.current.on('disconnect', () => {
      setIsConnected(false)
      removeAllDeviceStates()
      setTimeout(() => {
        socket.current?.connect()
      }, 1000)
    })

    socket.current.on(
      'client-disconnected',
      (data: ClientDisconnectedEventData) => {
        removeDeviceState(data.clientId)
      },
    )

    return () => {
      if (socket.current) {
        socket.current.off('client-disconnected')
        socket.current.off('disconnect')
        socket.current.off('update-device-state')
        socket.current.off('connect')
      }
    }
  }, [])

  useEffect(() => {
    console.log(`connected ${isConnected}`)
  }, [isConnected])

  useEffect(() => {
    console.log(JSON.stringify(deviceStateList))
  }, [deviceStateList])
}
