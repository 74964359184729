import React, { SetStateAction } from 'react'
import classes from '../../DashBoardItem.module.sass'
import { Status } from '../../../../../../common/components/status/Status'
import {
  DeviceItemInterface,
  ParkingInterface,
  SalesReportForDashboardItemInterface,
  Way,
} from '../../../../../../common/interfaces/interfaces'
import { useSide1 } from './hooks/useSide1'
import online from '../../../../../../assets/images/icons/online.svg'
import offline from '../../../../../../assets/images/icons/offline.svg'

type Props = {
  parking: ParkingInterface
  getDeviceListByParkingId: (parkingId: string) => DeviceItemInterface[]
  startOpenBarrier: (deviceId: string, parkingId: string, way: Way) => void
  checkDeviceActive: (id: string) => boolean
  getParkingRevenue: (id: string) => SalesReportForDashboardItemInterface
  getMonthName: () => string
}

export const Side1 = ({
  parking,
  getDeviceListByParkingId,
  startOpenBarrier,
  checkDeviceActive,
  getParkingRevenue,
  getMonthName,
}: Props) => {
  const { barrierIn, barrierOut } = useSide1(parking)

  return (
    <div className={classes.SideContainer}>
      <div className={classes.PlacesBlock}>
        <div className={classes.ForBorder}>
          <div className={classes.PlacesAvailable}>
            Доступно парковочных мест
            <div className={classes.Quantity}>20</div>
          </div>
        </div>
        <div className={classes.ForBorder}>
          <div className={classes.PlacesInUse}>
            Занято парковочных мест
            <div className={classes.Quantity}>20</div>
          </div>
        </div>
      </div>
      <div className={classes.BarrierStatusBlock}>
        {getDeviceListByParkingId(parking.id).length > 0 ? (
          getDeviceListByParkingId(parking.id).map((el) => (
            <div className={classes.BarrierBlock} key={el.id}>
              <div className={classes.StatusAndName}>
                <div
                  className={
                    checkDeviceActive(el.deviceId)
                      ? classes.StatusBlockOnline
                      : classes.StatusBlock
                  }
                >
                  <img
                    className={classes.StatusImage}
                    src={
                      el.id && checkDeviceActive(el.deviceId) ? online : offline
                    }
                  />
                  <div>
                    {el.id && checkDeviceActive(el.deviceId)
                      ? 'Online'
                      : 'Offline'}
                  </div>
                </div>
                <div>{el.name}</div>
              </div>
              <div className={classes.Buttons}>
                <button
                  className={classes.BarrierButtonLeft}
                  disabled={!el.id || !checkDeviceActive(el.deviceId)}
                  onClick={() =>
                    el.id && startOpenBarrier(el.deviceId, el.parkingId, Way.in)
                  }
                >
                  Открыть на въезд
                </button>
                <button
                  className={classes.BarrierButtonRight}
                  disabled={!el.id || !checkDeviceActive(el.deviceId)}
                  onClick={() =>
                    el.id &&
                    startOpenBarrier(el.deviceId, el.parkingId, Way.out)
                  }
                >
                  Открыть на выезд
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className={classes.NoDevices}>Нет устройств</div>
        )}
      </div>

      <div className={classes.RevenueBlock}>
        <div className={classes.ForBorder}>
          <div className={classes.RevenueItem}>
            Выручка сегодня
            <div className={classes.Amount}>{`${
              getParkingRevenue(parking.id).day / 100
            } руб.`}</div>
          </div>
        </div>
        <div className={classes.ForBorder}>
          <div className={classes.RevenueItem}>
            {`Выручка ${getMonthName()}`}
            <div className={classes.Amount}>{`${
              getParkingRevenue(parking.id).month / 100
            } руб.`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
