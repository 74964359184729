import React from 'react'
import classes from './DashBoard.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { ParkingInterface } from '../../common/interfaces/interfaces'
import { useDashBoard } from './hooks/useDashBoard'
import { DashBoardItem } from './components/parkingItem/DashBoardItem'
import { Modal } from '../../common/components/modal/Modal'
import { Activate } from './components/activate/Activate'
import { FilterWithGroupSelection } from '../../common/components/filterWithGroupSelection/FilterWithGroupSelection'
import { Pagination } from '../../common/components/pagination/Pagination'
import { CardLoader } from '../../common/components/cardLoader/CardLoader'
import { ActivateBarrier } from '../../common/components/activateBarrier/ActivateBarrier'

export const DashBoard = () => {
  const {
    list,
    currentParking,
    setCurrentParking,
    modalActive,
    setModalActive,
    modalActive1,
    setModalActive1,
    totalCount,
    limit,
    offset,
    setOffset,
    loading,
    message,
    partnersList,
    currentUser,
    inputHandler,
    data,
    checkDeviceActive,
    startOpenBarrier,
    getDeviceListByParkingId,
    deviceId,
    parkingId,
    way,
    getParkingRevenue,
    startActivateParking,
    activateParking,
    getMonthName
  } = useDashBoard()

  

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderWrapper}>
        <PageHeader length={totalCount} />

        {currentUser.super && (
          <FilterWithGroupSelection
            emptyText="Выберите партнера"
            allText="Все партнеры"
            initialValue={partnersList}
            inputHandler={inputHandler}
            fieldName="partnerList"
            selected={data.partnerList}
            // itemValidationResult={getValidationResult('parkingList')}
            // onBlur={setDirty}
          />
        )}
      </div>
      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((parking: ParkingInterface) => (
            <DashBoardItem
              startOpenBarrier={startOpenBarrier}
              checkDeviceActive={checkDeviceActive}
              parking={parking}
              key={parking.id}
              setCurrentParking={setCurrentParking}
              setModalActive={setModalActive}
              getDeviceListByParkingId={getDeviceListByParkingId}
              getParkingRevenue = {getParkingRevenue}
              startActivateParking = {startActivateParking}
              getMonthName = {getMonthName}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <CardLoader />
        )}
      </div>

      <Pagination
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive} central>
        <Activate
          currentParking={currentParking}
          setModalActive={setModalActive}
          activateParking = {activateParking}
        />
      </Modal>
      <Modal
        modalActive={modalActive1}
        setModalActive={setModalActive1}
        central
        cantClose
      >
        <ActivateBarrier
          setModalActive={setModalActive1}
          deviceId={deviceId}
          parkingId={parkingId}
          way={way}
        />
      </Modal>
    </div>
  )
}
